import {Injectable} from '@angular/core'
import {Router} from '@angular/router'
import {
  HelperService,
  SingleSignOnService,
  SparbankenUser,
  TokenPayload
} from '@sparbanken-syd/sparbanken-syd-bankid'
import {BehaviorSubject, EMPTY, Observable, of} from 'rxjs'
import {catchError, switchMap} from 'rxjs/operators'
import {environment} from '../../environments/environment'

/**
 * Info about the logged in state to be communicated to
 * other parts of the application
 */
export class SpbConfiguration {
  /**
   * If we are administrators we can do stuff.
   */
  isAdmin: boolean = false
  /**
   * The user that actually block other peoples bankid.
   */
  isUser: boolean = false

  constructor(isAdmin: boolean, isUser: boolean) {
    this.isAdmin = isAdmin
    this.isUser = isUser
  }

  /**
   * This must always be set and should be true only if we can
   * proceed with application logic.
   */
  get isReady() {
    return this.isUser || this.isAdmin
  }
}


@Injectable({
  providedIn: 'root'
})

export class ConfigService {
  /**
   * The access token, primarily needed for the auth interceptor
   */
  public accessToken$ = new BehaviorSubject<string | null>(null)

  public configState$: Observable<SpbConfiguration | null>
  private pConfigState$ = new BehaviorSubject<SpbConfiguration | null>(null)

  public currentUser$: Observable<SparbankenUser | null>
  private pCurrentUser$ = new BehaviorSubject<SparbankenUser | null>(null)

  constructor(
    private ssoService: SingleSignOnService,
    private helperService: HelperService,
    private router: Router
  ) {
    this.configState$ = this.pConfigState$.asObservable()
    this.currentUser$ = this.pCurrentUser$.asObservable()
  }

  /**
   * This is called from the app module bootstrapper only. So
   * it will happen once and once only.
   */
  public bootstrap(): Observable<boolean> {
    return this.sso()
      .pipe(
        switchMap((value: string | null) => {
          return this.setToken(value)
        })
      )
  }

  /**
   * Called whenever we have token, a token can come from two valid sources
   *
   * 1. From the SSO service
   * 2. From BankID login.
   *
   * We do not care, and we validate and set whatever we get.
   */
  public setToken(token: string | null): Observable<boolean> {
    const payLoad: TokenPayload | null = HelperService.GetTokenPayload(token)
    if (payLoad) {
      this.accessToken$.next(token)
      this.setUserData(payLoad)
    }
    return of(true)
  }


  /**
   * Call the SSO service, if we get something we return
   * that. Otherwise, nothing. Must be anonymous since we
   * call it from merge
   */
  public sso = (): Observable<string> | never => {
    return this.ssoService.getToken(environment.authServiceUrl, environment.domain)
      .pipe(
        catchError(() => {
          // We need to reset when the SSO service logs us out, but there's no need to call logout again
          this.reset()
          return EMPTY
        })
      )
  }

  /**
   * Reset all admin values, including SSO and
   * go back to log-in.
   */
  public logout(): void {
    // Blindly just log out from SSO, ignore any errors
    this.ssoService.deleteToken(environment.authServiceUrl).subscribe()
    this.reset()
  }

  public reset(): void {
    // This can potentially be a long list of resets...
    this.accessToken$.next(null)
    this.pConfigState$.next(null)
    this.pCurrentUser$.next(null)
    // Go to log-in
    this.router.navigate(['/login']).then()
  }

  /**
   * In many cases we also want to fetch the user info we do this
   * totally asynchronous and happily accept that the access token
   * is set properly etc.
   *
   * In Legacy applications we have used this to trigger a reset, which is
   * bad.
   */
  private setUserData(payload: TokenPayload): void {
    this.helperService.getCurrentUser(`${environment.authServiceUrl}`)
      .subscribe({
        next: (user: SparbankenUser) => {
          this.pCurrentUser$.next(user)

          this.pConfigState$.next(new SpbConfiguration(
            payload.roles.includes('blockBankIdAdmin'),
            payload.roles.includes('blockBankIdUser')
          ))
        }
      })
  }
}
