<div>
  <div class="top">
    <div *ngIf="configService.currentUser$ | async as user" class="name-holder">
      <span>{{user.name}}</span>
    </div>
  </div>
</div>
<div class="middle-holder">
  <div class="middle">
    <div (click)="navigate('https://www.sparbankensyd.se/')" class="logo">
      Spärrtjänst
    </div>
    <div class="spb-filler"></div>
    <div *ngIf="configService.configState$ | async as config" class="middle-menu">
      <div *ngIf="config.isReady">
        <button [matMenuTriggerFor]="menu" mat-button>
          <mat-icon>menu</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button [routerLink]="['/main']" mat-menu-item>Spärra bankid</button>
          <div *ngIf="config.isAdmin">
            <button [routerLink]="['/admin']" mat-menu-item>Admin tjänster</button>
          </div>
          <button (click)="configService.logout()" mat-menu-item>Logga ut</button>
        </mat-menu>
      </div>
    </div>
  </div>
</div>
