import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {HeaderComponent} from './header/header.component'
import {WaitDialogComponent} from './wait-dialog/wait-dialog.component'
import {MatDialogModule} from '@angular/material/dialog'
import {MatButtonModule} from '@angular/material/button'
import {MatMenuModule} from '@angular/material/menu'
import {MatIconModule} from '@angular/material/icon'
import {RouterModule} from '@angular/router'
import {SpbPaginatorSv} from '@sparbanken-syd/sparbanken-syd-theme'
import {MatPaginatorIntl} from '@angular/material/paginator'

@NgModule({
  declarations: [
    HeaderComponent,
    WaitDialogComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    RouterModule
  ],
  exports: [
    HeaderComponent,
    WaitDialogComponent,
  ],
  providers: [
    {provide: MatPaginatorIntl, useClass: SpbPaginatorSv}
  ]
})
export class SpbCommonModule {
}
